import React, { useState } from 'react';

import Switch from 'common-ui-components/Switch';
import BackofficeController from 'global/api/controller/BackofficeController';
import Tenant from 'model/Tenant';
import DebuggerConsole from 'utils/DebuggerConsole';

interface Props {
  tenantId: Tenant['id'];
  currentState: Tenant['isSubscriptionExpired'];
}

export default function TenantSubscriptionExpired({ tenantId, currentState }: Props) {
  const [subscriptionExpired, setSubscriptionExpired] = useState<boolean | undefined>(currentState);
  const [loading, setLoading] = useState(false);

  const handleSubscriptionChange = async (nextValue: Tenant['isSubscriptionExpired']) => {
    try {
      setLoading(true);
      await BackofficeController.Tenant.setTenantSubscription(tenantId, nextValue);
      setSubscriptionExpired(nextValue);
    } catch (error) {
      DebuggerConsole.error('Couldn\'t set tenant status', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Switch
      active={subscriptionExpired ?? false}
      loading={loading}
      onChange={handleSubscriptionChange}
    />
  );
}
